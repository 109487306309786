<template>
  <v-row no-gutters>
    <v-col cols="4" class="d-flex align-center justify-start">
      <calendar-input
        v-if="wantsDate"
        v-model="selectedDate"
        dense
        :label="$trans('date')"
        :rules="[rules.required]"
        :min="today"
      />
      <v-select
        v-else
        :items="weekdays"
        hide-details
        :value="selectedDay"
        outlined
        dense
        full-width
        class="mr-2 day-select"
        @change="onDateChange"
      />
    </v-col>
    <v-col cols="4" class="d-flex align-center justify-center">
      <v-checkbox v-model="wantsDate" :label="$trans('select_date')" />
    </v-col>
    <v-col cols="4" class="d-flex align-center justify-start">
      <div class="mr-2">
        <c-time-picker :time="selectedTime" @update="onTimeChange" />
      </div>
      <v-btn icon fab text @click.native="remove(index)">
        <v-icon>$trash-default</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import CTimePicker from "@/components/CSchedule/CTimePicker";
import CalendarInput from "@/lib/calendesk-js-library/components/CalendarInput.vue";
import { required } from "@/lib/calendesk-js-library/forms/validators";

export default {
  components: { CalendarInput, CTimePicker },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      wantsDate: false,
      selectedDay: null,
      selectedDate: null,
      selectedTime: null,
      rules: {
        required,
      },
      weekdays: [
        {
          text: this.$trans("monday"),
          value: "monday",
        },
        {
          text: this.$trans("tuesday"),
          value: "tuesday",
        },
        {
          text: this.$trans("wednesday"),
          value: "wednesday",
        },
        {
          text: this.$trans("thursday"),
          value: "thursday",
        },
        {
          text: this.$trans("friday"),
          value: "friday",
        },
        {
          text: this.$trans("saturday"),
          value: "saturday",
        },
        {
          text: this.$trans("sunday"),
          value: "sunday",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
    }),
    today() {
      return this.$moment(new Date()).format(this.$helpers.dateFormat);
    },
  },
  watch: {
    item() {
      this.reload();
    },
    index() {
      this.reload();
    },
    selectedDate() {
      this.onChange();
    },
    wantsDate(wantsDate) {
      if (wantsDate) {
        this.selectedDate = this.selectedDate ? this.selectedDate : this.today;
        this.selectedDay = null;
      } else {
        this.selectedDate = null;
        this.selectedDay = this.selectedDay ? this.selectedDay : "monday";
      }
      this.onChange();
    },
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      if (this.item.day) {
        this.wantsDate = false;
        this.selectedDay = this.item.day;
        this.selectedDate = null;
      } else {
        this.wantsDate = true;
        this.selectedDay = null;
        this.selectedDate = this.item.date;
      }

      this.selectedTime = this.item.start_time;
    },
    remove(index) {
      this.$emit("remove", index);
    },
    onChange() {
      this.$emit("change", {
        day: this.selectedDay,
        date: this.selectedDate,
        start_time: this.selectedTime,
        index: this.index,
      });
    },
    onTimeChange(time) {
      this.selectedTime = time;
      this.onChange();
    },
    onDateChange(time) {
      this.selectedDay = time;
      this.onChange();
    },
  },
};
</script>
